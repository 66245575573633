import React, { useEffect, useState } from 'react';
import {
    NavRight,
    Link,
    Popup,
    Toolbar,
    Button,
    Page,
    Navbar,
    Row,
    Col,
    Checkbox,
    Block,
    Input,
} from 'framework7-react';

import axios from 'axios';
import TimeKeeper from 'react-timekeeper';

import logo from '../../assets/lodl_logo_full.svg';
import bioIcon from '../../assets/bio.svg';
import successIcon from '../../assets/success.svg';
import errorIcon from '../../assets/error.svg';
import countIcon from '../../assets/count.svg';

import CircularProgressBar from './components/progressRing';

import addressbar from 'addressbar';

export default function (props) {
    // Freeform text submitted by user
    const [additionalText, setAdditionalText] = useState("");

    // Network state
    const [backendAvailable, setBackendAvailable] = useState(false);

    // Next baker weekday
    // We initialize this to the next saturday with the due day on friday.
    // It will be overwritten with the correct value once this is loaded.
    const [bakerDay, setBakerday] = useState(getNextPossibleWeekday([], undefined)[0]);

    // Menu data
    const [data, setData] = useState(null);
    const [networkData, setNetworkData] = useState(null);

    // Error Popup
    const [errorPopupOpened, setErrorPopupOpened] = useState(false);

    // Overall price
    const [overallPrice, setOverallPrice] = useState(0);

    // Order Popup
    const [popupOpened, setPopupOpened] = useState(false);

    // Order posted
    const [isPostingOrder, setIsPostingOrder] = useState(false);

    // Timepicker values
    const [showTime, setShowTime] = useState(false);
    const [time, setTime] = useState("08:00");

    // Baker options
    const [bakerDueDay, setBakerDueDay] = useState(5);
    const [bakerDueTime, setBakerDueTime] = useState("09:00");

    // Special dates
    const [specialDates, setSpecialDates] = useState([]);

    // Terms and conditions checkbox
    const [termsAccepted, setTermsAccepted] = useState(false);

    const onBackButtonEvent = () => {
        if (location.href.includes("auswahl")) {
            props.f7router.back();
            document.documentElement.style.setProperty('--f7-navbar-bg-color', '#f7f7f8'); // No idea why that is neccessary.
            document.querySelector('meta[name="theme-color"]').setAttribute("content", '#f7f7f8');
        }
        else if (location.href.includes("forster")) {
            props.f7router.navigate("/bestellungen/forster/", {});
        }
        else if (location.href.includes("duernberg")) {
            props.f7router.navigate("/bestellungen/duernberg/", {});
        }
        else if (location.href.includes("grill")) {
            props.f7router.navigate("/bestellungen/grill/", {});
        }
    }

    useEffect(() => {
        getMenu();

        addressbar.value = `${addressbar.origin}/bestellungen/${props.name}/`;

        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    // Reevaluate overall price after menu changed.
    useEffect(() => {
        liveUpdateOverallPrice();
    }, [data]);


    const liveUpdateOverallPrice = () => {
        let price = 0;

        if (data === undefined || data === null || document === undefined) {
            return;
        }

        for (const category of data?.categories) {
            for (const item of category.items) {
                if (document.getElementById("input_item" + item.id)?.value?.length > 0) {
                    let amount = parseInt(document.getElementById("input_item" + item.id).value ?? "0");
                    price += amount * item.price;
                }
            }
        }

        setOverallPrice(price);
    }

    const getMenu = () => {
        axios.get(`${addressbar.origin}/bestellungen/${props.name}/menu`) // Will only work when served using "rocket".
            .then(result => {
                if (result.status == 200) {
                    setData(result.data);
                    setNetworkData(result.data);
                    setBakerDueDay(result.data.baker.dueDay);
                    setBakerDueTime(result.data.baker.dueTime);
                    document.documentElement.style.setProperty('--f7-navbar-bg-color', result.data?.baker?.color);
                    document.querySelector('meta[name="theme-color"]').setAttribute("content", result.data?.baker?.color);

                    setBackendAvailable(true);

                    let lastOrder = JSON.parse(localStorage.getItem("lastOrder" + String(result.data?.baker?.id ?? -1)));

                    if (lastOrder != null && lastOrder?.baker?.lastMenuUpdate == result.data.baker.lastMenuUpdate) {
                        setLastOrderData(lastOrder);
                        setData(lastOrder);
                        // Its pretty obvious, that the date will most likely differ, but maybe the customer will choose the same time...
                        setTime(lastOrder.dueTime);
                    }

                    getSpecialDates(result.data);
                }
            })
            .catch(error => {
                console.error('Error reading menu:', error);
                setBackendAvailable(false);
            });
    }

    const getSpecialDates = (menuResult) => {
        axios.get(`${addressbar.origin}/bestellungen/${props.name}/special-dates`) // Will only work when served using "rocket".
            .then(result => {
                if (result.status == 200) {
                    setSpecialDates(result.data.slice());
                    setBakerday(getNextPossibleWeekday(result?.data, menuResult?.baker));

                    setBackendAvailable(true);
                }
            })
            .catch(error => {
                console.error('Error loading special dates:', error);
                setBackendAvailable(false);
            });
    }

    useEffect(() => {
        if (bakerDay.length === 0) {
            return;
        }

        const date = bakerDay[0];
        for (let i = 0; i < specialDates.length; i++) {
            if (compareDays(date, parseDateFromString(specialDates[i].specialDay))) {
                setBakerDueDay(parseDateFromString(specialDates[i].dueDate).getDay());
                setBakerDueTime(specialDates[i].dueTime.substring(0, specialDates[i].dueTime.length - 3));
                return;
            }
        }

        // Otherwise use the defaults for the baker.
        setBakerDueDay(networkData?.baker?.dueDay ?? 5);
        setBakerDueTime(networkData?.baker?.dueTime ?? "09:00");
    }, [bakerDay, specialDates])

    return (
        <Page name="order">
            {/* Navbar */}
            <Navbar
                title={(data?.baker?.name ?? "Laden...") + (data?.baker?.location?.length > 0 ? (" - " + data?.baker?.location) : "")}
                backLink
                backLinkForce
                onBackClick={() => props.f7router.back()}
                style={{ textTransform: "uppercase" }}
                sliding={false}
                className="noprint">
            </Navbar>

            {/* Toolbar for placing the order */}
            <Toolbar bottom
                className="noprint" style={{ height: "calc(68px + env(safe-area-inset-bottom))" }} >
                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", textAlign: "center", fontWeight: "bold", marginBottom: "0.2em" }}>
                        Vorläufiger Gesamtpreis: {formatEuro(overallPrice)}&nbsp;€ <a
                            title="Zusätzlich bestellte Positionen (Anmerkungen) sind noch nicht eingerechnet, daher ist der angezeigte Gesamtpreis unverbindlich."
                            onClick={() => document.getElementById("footnote-1").scrollIntoView()}
                            style={{ verticalAlign: "super", fontSize: "0.6em" }} href="#footnote-1">[1]</a>
                    </div>
                    <div>
                        <Button
                            text="Jetzt Bestellen"
                            fill="true"
                            style={{ fontWeight: "bold", marginRight: "3em", marginLeft: "3em", letterSpacing: "0.5em" }}
                            disabled={!backendAvailable || isPostingOrder}
                            onClick={() => {
                                if (getAmounts(data) === false) {
                                    return;
                                }

                                if (termsAccepted === false) {
                                    alert("Sie müssen der Datenschutzbestimmung zustimmen, um eine Bestellung aufgeben zu können.");
                                    document.getElementById("terms-and-conditions").scrollIntoView();
                                    return;
                                }
                                data.timestamp = createDateString(new Date());
                                data.additionalText = additionalText;
                                data.isMenu = false;
                                setIsPostingOrder(true);

                                // Check if all neccessary data is set.
                                if (getPersonalData(data, bakerDay[0])) { // We will always only have one date.
                                    // Send a post request with the order to the backend.
                                    axios.post(`${addressbar.origin}/bestellungen/${props.name}/order`, data, {  // Will only work when served using "rocket".
                                        headers: {
                                            "Content-Type": "application/json",
                                        }
                                    })
                                        .then(function (response) {

                                            if (response.status === 200) {
                                                // Everything went smoothly...
                                                setPopupOpened(true);
                                                // Trigger animation restart.
                                                document.getElementById("success-icon").data = "";
                                                document.getElementById("success-icon").data = successIcon;

                                                // Save this as the last order.
                                                localStorage.setItem("lastOrder" + String(data?.baker?.id), JSON.stringify(data));
                                            }
                                            else {
                                                // Display error message
                                                console.error(response.statusText);

                                                setErrorPopupOpened(true);
                                                document.querySelector("#error-text").textContent = response.statusText;
                                            }
                                        })
                                        .catch(function (error) {
                                            // Display error message
                                            console.error(error);

                                            setErrorPopupOpened(true);
                                            document.querySelector("#error-text").textContent = error;
                                        })
                                        .finally(() => {
                                            setIsPostingOrder(false);
                                        });
                                } else {
                                    setIsPostingOrder(false);
                                }
                            }}
                        ></Button>
                    </div>
                </div>


            </Toolbar>

            {/* Popup indicating the successful order. */}
            <Popup
                className="success-order-popup noprint"
                opened={popupOpened}
                onPopupClosed={() => {
                    setPopupOpened(false);
                    props.f7router.back();
                    // The popstate event seems to be confused by the popup closing here.
                    document.documentElement.style.setProperty('--f7-navbar-bg-color', '#f7f7f8'); // No idea why that is neccessary.
                    document.querySelector('meta[name="theme-color"]').setAttribute("content", '#f7f7f8');
                    addressbar.value = `${addressbar.origin}/bestellungen/auswahl/`;
                }}
            >
                <Page>
                    <Navbar title="Bestellung erfolgt">
                        <NavRight>
                            <Link popupClose>Schließen</Link>
                        </NavRight>
                    </Navbar>
                    <Block style={{ textAlign: "center" }}>
                        <object type="image/svg+xml" data={successIcon} id="success-icon" style={{ width: "70%", height: "70%", textAlign: "center" }}></object>
                        <p>
                            Ihre Bestellung im Wert von {formatEuro(overallPrice)}&nbsp;€<a
                                title="Zusätzlich bestellte Positionen (Anmerkungen) sind noch nicht eingerechnet, daher ist der angezeigte Gesamtpreis unverbindlich."
                                style={{ verticalAlign: "super", fontSize: "0.6em" }} href="#footnote-1"
                                onClick={() => document.getElementById("footnote-1").scrollIntoView()}>[1]</a> wurde erfolgreich übermittelt. Sie erhalten in Kürze eine Bestätigung in Ihrem Postfach, sofern sie eine E-Mail Adresse angegeben haben.
                        </p>
                        <p>
                            Zudem können sie Ihre Bestellung jederzeit <a onClick={() => window.print()}>drucken</a>.
                        </p>
                    </Block>
                </Page>
            </Popup>

            {/* Popup indicating the successful order. */}
            <Popup
                className="error-popup noprint"
                opened={errorPopupOpened}
                onPopupClosed={() => setErrorPopupOpened(false)}
            >
                <Page>
                    <Navbar title="Fehler">
                        <NavRight>
                            <Link popupClose>Schließen</Link>
                        </NavRight>
                    </Navbar>
                    <Block style={{ textAlign: "center" }}>
                        <object type="image/svg+xml" data={errorIcon} id="error-icon" style={{ width: "70%", height: "70%", textAlign: "center" }}></object>
                        <p>
                            Es ist ein Fehler aufgetreten, weswegen Ihre Bestellung leider nicht entgegengenommen werden konnte:
                        </p>
                        <pre>
                            <code id="error-text">

                            </code>
                        </pre>
                        <p>
                            Bitte prüfen sie Ihre Netzwerkverbindung und versuchen sie es später erneut. Alternativ können sie Ihre Bestellung jederzeit <a onClick={() => window.print()}>ausdrucken</a> und persönlich im LODL abgeben.
                        </p>
                        <p>
                            Sollte das Problem anhalten, bitten wir sie um eine kurze Mitteilung über das <a onClick={() => window.open("http://www.dorfladen-lorenzreuth.de/#kontakt", "_blank")}>Kontaktformular</a>.
                        </p>
                    </Block>
                </Page>
            </Popup>

            {backendAvailable ?
                <Block style={{ marginBottom: "20px" }}
                    className="mainBlock">

                    {/* Header with logo */}
                    <Row>
                        <Col>
                            <Row>
                                <Col width="100" medium="60">
                                    <Block>
                                        <Row style={{ color: "#A89467", fontSize: 'x-large', textTransform: "uppercase" }}>
                                            {getDayByNumber(data.baker.weekday)}
                                        </Row>
                                        <Row style={{ color: "#A89467", fontSize: "xx-large", fontWeight: "bold", textTransform: "uppercase" }}>
                                            {data.baker.name}
                                        </Row>
                                        <Row style={{ fontSize: "x-large", textTransform: "uppercase" }}>
                                            Kundenbestellung
                                        </Row>
                                    </Block>
                                </Col>
                                <Col width="100" medium="40" style={{ height: "100%", textAlign: "center" }}>
                                    <img src={logo} alt="LODL Logo" style={{ maxHeight: "200px" }}></img>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    {/* Due date notice */}
                    <Row style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center", width: "90%", textTransform: "uppercase" }}>
                            Bitte bis spätestens <b>{getDayByNumber(bakerDueDay)} {bakerDueTime} Uhr</b> absenden.
                        </p>
                    </Row>

                    {/* Customer data */}
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col width="100" style={{ maxWidth: "900px" }}>
                            <Row>
                                <Col width="100" medium="50">
                                    <label htmlFor="customer-name" style={{ fontWeight: "bold" }}>NAME</label>
                                    <input
                                        type="text"
                                        id="customer-name"
                                        onChange={() => document.querySelector("#customer-name").classList.remove("input-problem")}
                                        style={{ paddingLeft: "0.4em" }}></input>
                                </Col>
                                <Col width="100" medium="50">
                                    <label htmlFor="customer-phone" style={{ fontWeight: "bold" }}>TELEFONNUMMER</label>
                                    <input
                                        type="text"
                                        id="customer-phone"
                                        onChange={() => document.querySelector("#customer-phone").classList.remove("input-problem")}
                                        style={{ paddingLeft: "0.4em" }}></input>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ display: "flex", justifyContent: "center" }}
                        /* The email is not needed in the print-out. */
                        className="noprint">
                        <Col width="100" style={{ maxWidth: "900px" }}>
                            <Row>
                                <Col width="0" medium="25"></Col>
                                <Col width="100" medium="50">
                                    <label htmlFor="customer-mail" style={{ fontWeight: "bold" }}>E-MAIL</label>
                                    <input type="text" id="customer-mail" style={{ paddingLeft: "0.4em" }}></input>
                                </Col>
                                <Col width="0" medium="25"></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col width="100" style={{ maxWidth: "900px" }}>
                            <Row>
                                <Col width="100" medium="50">
                                    <label htmlFor="customer-takeaway-date" style={{ fontWeight: "bold" }}>ABHOLTAG</label>
                                    <Input
                                        type="datepicker"
                                        id="customer-takeaway-date"
                                        value={bakerDay}
                                        required
                                        readonly
                                        inputStyle={{ paddingLeft: "0.4em" }}
                                        onCalendarChange={(dates) => {
                                            setBakerday(dates);
                                        }}
                                        onChange={() => {
                                            document.querySelector("#customer-takeaway-date").classList.remove("input-problem");
                                        }}
                                        calendarParams={{
                                            openIn: "customModal",
                                            locale: "de-DE",
                                            disabled: function (date) {
                                                return getDisabledDates(date, specialDates, networkData?.baker);
                                            },
                                            header: true,
                                            footer: true,
                                            closeOnSelect: true,
                                            toolbarCloseText: "Fertig",
                                            headerPlaceholder: "Abholtag auswählen",
                                            dateFormat: "DD, d. MM yyyy"
                                        }}
                                    />

                                </Col>
                                <Col width="100" medium="50">
                                    <label htmlFor="customer-takaway-time" style={{ fontWeight: "bold" }}>ABHOLZEIT</label>
                                    <input
                                        type="text"
                                        id="customer-takeaway-time"
                                        style={{ paddingLeft: "0.4em" }}
                                        readOnly
                                        onClick={() => setShowTime(true)}
                                        value={time}></input>

                                    <div style={{ position: "fixed", left: "50%", top: "50%", transform: "translate(-50%, -50%)", zIndex: "20" }}>
                                        {showTime &&
                                            <TimeKeeper
                                                time={time}
                                                onChange={(newTime) => {
                                                    setTime(addLeadingZeroToHour(newTime.formatted24));
                                                    document.querySelector("#customer-takeaway-time").classList.remove("input-problem");
                                                }}
                                                onDoneClick={() => setShowTime(false)}
                                                doneButton={() => (
                                                    <div
                                                        style={{ textAlign: "center", padding: "10px 0", cursor: "pointer" }}
                                                        onClick={() => setShowTime(false)}
                                                    >
                                                        Fertig
                                                    </div>
                                                )}
                                                hour24Mode
                                                switchToMinuteOnHourSelect
                                                /*closeOnMinuteSelect*/ /* This needs to be enabled device dependent... */
                                                coarseMinutes={5}
                                                forceCoarseMinutes
                                            />
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Privacy and terms and condidtions. */}
                    <Row style={{ textAlign: "center", scrollMarginTop: "20rem" }} id="terms-and-conditions">
                        <p style={{ textAlign: "center", width: "90%", textTransform: "uppercase" }}>
                            Hiermit bestätige ich, dass ich die <a style={{ fontWeight: "bold" }} href="https://dorfladen-lorenzreuth.de/datenschutz/" onClick={() => openInNewTab("https://dorfladen-lorenzreuth.de/datenschutz/")}>Datenschutzvereinbarungen</a> gelesen habe
                            und mit diesen einverstanden bin: <Checkbox
                                defaultChecked={false}
                                value={termsAccepted}
                                name="terms-checkbox"
                                onChange={(event) => setTermsAccepted(event.target.checked)}></Checkbox>
                        </p>
                    </Row>

                    {/* Menu */}
                    <div>
                        <MenuItems categories={data.categories} liveUpdateOverallPrice={liveUpdateOverallPrice}></MenuItems>
                    </div>
                    <div>
                        <label style={{ fontWeight: "bold" }} className={additionalText.length > 0 ? "" : "noprint"}>Anmerkungen zur Bestellung:</label>
                        <textarea onChange={(event) => setAdditionalText(event.target.value)}
                            placeholder="Anmerkungen"
                            style={{ border: "1px solid black", width: "100%", padding: "5px" }}
                            maxLength={1000}
                            className={additionalText.length > 0 ? "" : "noprint"}
                            title="Hier können sie falls gewünscht Anmerkungen zu Ihrer Bestellung als Freitext anfügen. Es können maximal 1000 Zeichen eingegeben werden."
                        ></textarea>
                    </div>

                </Block>
                :
                <Block>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "inline-block" }}>
                            <CircularProgressBar></CircularProgressBar>
                        </div>
                    </div>
                </Block>
            }
            {/* Footer with company details */}
            <p id="footnote-1" style={{ marginLeft: "1em", fontSize: "0.7em" }}>[1] Zusätzlich bestellte Positionen (Anmerkungen) sind noch nicht eingerechnet, daher ist der angezeigte Gesamtpreis unverbindlich.</p>
            <p style={{ textAlign: "center" }}>LODL – DORFLADEN&nbsp;LORENZREUTH&nbsp;UG . THIERSHEIMER STRASSE&nbsp;1 . 95615&nbsp;MARKTREDWITZ</p>
            <p style={{ textAlign: "center", marginBottom: "32px", lineHeight: "0.5em" }} className="noprint"><a href="https://dorfladen-lorenzreuth.de/IMPRESSUM/" onClick={() => openInNewTab("https://dorfladen-lorenzreuth.de/IMPRESSUM/")}>IMPRESSUM</a></p>
        </Page>
    );
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const MenuItems = (props) => {
    let leftItems = [];
    let rightItems = [];
    props?.categories?.forEach((category, key) => {
        if (category.isLeftCol) {
            leftItems.push(
                <CategoryItemList category={category} liveUpdateOverallPrice={props.liveUpdateOverallPrice} key={key}></CategoryItemList>
            );
        }
        else {
            rightItems.push(
                <CategoryItemList category={category} liveUpdateOverallPrice={props.liveUpdateOverallPrice} key={key}></CategoryItemList>
            );
        }
    });
    return (
        <Row>
            <Col width="100" medium="50">{leftItems}</Col>
            <Col width="100" medium="50">{rightItems}</Col>
        </Row>
    )
}

const CategoryItemList = (props) => {
    let items = [];
    props?.category?.items?.forEach((item, key) => {
        items.push(
            <tr key={key}>
                <td style={{ textAlign: "right", paddingRight: "0.5em" }}>{item.id}
                </td>
                <td colSpan={item.price > 0 ? 1 : 2}>{item.name}</td>
                {item.price > 0 &&
                    <td style={{ textAlign: "right", paddingRight: "1.5em" }}>
                        {formatEuro(item.price)}&nbsp;€
                    </td>
                }
                <td>
                    <input
                        type="text"
                        id={"input_item" + item.id}
                        className="order-item"
                        defaultValue={item.amount === 0 ? "" : item.amount}
                        inputMode="numeric"
                        onChange={(e) => {
                            const regex = /^\d*$/;
                            if (regex.test(e.target.value) === false) {
                                e.preventDefault();
                                e.target.value = e.target.value.slice(0, -1);
                                alert("Bitte nur Ziffern eingeben.");
                            }
                            props.liveUpdateOverallPrice();
                        }}
                        style={{ textAlign: "center" }} />
                </td>
            </tr>
        )
    });
    return (
        <div style={{ marginTop: "1.2em", marginBottom: "1.2em" }}>
            <Row style={{ lineHeight: "18px", overflow: "visible", height: "38px" }}>
                <Col width="80" style={{ height: "100%", verticalAlign: "top" }}>
                    <h2 style={{ fontWeight: "bold", display: "inline", verticalAlign: "top" }}>{props.category.name}</h2>
                    {props.category.isBio &&
                        <img src={bioIcon} style={{ display: "inline", width: "3.5em", height: "3.5em", marginLeft: "1.5em" }}></img>
                    }
                </Col>
                <Col width="20" style={{ textAlign: "center", zIndex: -1 }}>
                    <img src={countIcon} style={{ width: "4em", height: "4em", marginTop: "0.6em" }}></img>
                </Col>
            </Row>
            {/*<img src={bioIcon} style={{display: "inline", width: "5em", height: "5em"}}></img>*/}
            <table style={{ width: "100%" }}>
                <colgroup>
                    <col span="1" style={{ width: "10%" }}></col>
                    <col span="1" style={{ width: "50%" }}></col>
                    <col span="1" style={{ width: "20%" }}></col>
                    <col span="1" style={{ width: "20%" }}></col>
                </colgroup>

                <tbody>
                    {items}
                </tbody>
            </table>
        </div>
    );
}

// Adds a zero when the hour is only a single digit value.
function addLeadingZeroToHour(time) {
    let hour = time.split(":")[0];

    if (hour.length < 2) {
        hour = "0" + hour;
        return hour + ":" + time.split(":")[1];
    }
    else {
        return time;
    }
}

// Get the desired amount for each item based on its ID.
function getAmounts(data) {
    let anythingOrdered = false;
    for (const category of data.categories) {
        for (const item of category.items) {
            if (document.getElementById("input_item" + item.id).value.length > 0) {
                let amount = parseInt(document.getElementById("input_item" + item.id).value ?? "0");
                item.amount = amount;
                anythingOrdered = true;
            }
            else {
                item.amount = 0;
            }
        }
    }

    if (anythingOrdered === false) {
        alert("Sie haben bisher nichts bestellt (Die aktuell gewählte Menge bei allen Waren ist null).");
        return false;
    }
    return true;
}

// Gets the customer data.
function getPersonalData(data, bakerDay) {
    let name = document.querySelector("#customer-name");
    let phone = document.querySelector("#customer-phone");
    let mail = document.querySelector("#customer-mail");

    // This is a special case due to F7s component model.
    let takeawayDate = document.querySelector("#customer-takeaway-date").children[0];

    let takeawayTime = document.querySelector("#customer-takeaway-time");

    if (!name.value || !takeawayDate.value || !takeawayTime.value || !phone.value) {
        alert("Bitte ergänzen sie zunächst die Angaben in den rot markierten Feldern.");

        // Highlight the missing fields
        if (!name.value) {
            name.classList.add("input-problem");
        }

        if (!takeawayDate.value) {
            takeawayDate.classList.add("input-problem");
        }

        if (!takeawayTime.value) {
            takeawayTime.classList.add("input-problem");
        }

        if (!phone.value) {
            phone.classList.add("input-problem")
        }

        return false;
    }

    if (mail.value) {
        if (!validateEmail(mail.value)) {
            alert("Die angegebene E-Mail Adresse ist ungültig. Bitte prüfen sie Ihre Eingabe.");

            return false;
        }
    }

    if (phone.value) {
        if (!validatePhoneNumber(phone.value)) {
            alert("Die angegebene Telefonnummer ist ungültig. Bitte prüfen sie Ihre Eingabe.");

            return false;
        }
    }

    // Assign input data to internal data object.
    data.customer.name = name.value;
    // We only want the date as ISO 8601.
    let utcDate = new Date(Date.UTC(bakerDay.getFullYear(), bakerDay.getMonth(), bakerDay.getDate()));
    data.dueDate = utcDate.toISOString().split('T')[0];
    data.dueTime = takeawayTime.value;
    data.customer.phone = phone?.value;
    data.customer.mail = mail?.value;

    return true;
}

// Validates email adress syntax using regex.
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// Validates german phone numbers using regex.
function validatePhoneNumber(phone) {
    const re = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
    return re.test(String(phone));
}

// Formats a value in cents as euros with two decimals.
function formatEuro(cents) {
    return [String(cents).padStart(3, '0').slice(0, -2), ",", String(cents).padStart(3, '0').slice(-2)].join('');
}

// Get the next date when the baker is available.
// The due date is also taken into account here.
function getNextPossibleWeekday(specialDates, baker) {
    if (!specialDates) {
        return [];
    }

    const availableDates = getAvailableDates(specialDates, baker);
    const now = new Date();

    for (let i = 0; i < availableDates.length; i++) {
        if (availableDates[i].dueDate > now) {
            return [availableDates[i].takeawayDate];
        }
    }

    return [];
}

/// Gets the possible dates in the next three weeks.
function getAvailableDates(specialDates, baker) {
    let datesList = [];

    for (let i = 0; i < 3; i++) {
        const saturday = nextDay(baker?.weekday ?? 6, i);
        let dueDate = nextDay(baker?.weekday ?? 6, i);
        dueDate.setDate(dueDate.getDate() - 1);
        dueDate.setHours(9, 0, 0, 0);
        if (checkDateProhibited(saturday, specialDates) === false) {
            datesList.push({
                takeawayDate: saturday,
                dueDate: dueDate
            });
        }
    }

    specialDates.forEach((specialDate) => {
        if (dateInNextThreeWeeks(parseDateFromString(specialDate.specialDay)) && specialDate.allowOrder) {
            let dueDate = parseDateFromString(specialDate.dueDate);
            dueDate.setHours(specialDate.dueTime.substring(0, 2), specialDate.dueTime.substring(3, 5), 0, 0);
            datesList.push({
                takeawayDate: parseDateFromString(specialDate.specialDay),
                dueDate: dueDate
            });
        }
    });

    datesList.sort(function (a, b) {
        return a.takeawayDate - b.takeawayDate;
    });

    return datesList;
}

function dateInNextThreeWeeks(date) {
    let now = new Date();
    now.setHours(0, 0, 0, 0);

    let threeWeeks = new Date();
    threeWeeks.setDate(threeWeeks.getDate() + 3 * 7);
    threeWeeks.setHours(23, 59, 59, 999);

    return now < date && date < threeWeeks;
}

function checkDateProhibited(date, specialDates) {
    for (let i = 0; i < specialDates.length; i++) {
        if (compareDays(date, parseDateFromString(specialDates[i].specialDay)) && specialDates[i].allowOrder === false) {
            return true;
        }
    }

    return false;
}

/// Returns the next date with a specific weekday (0-6)=(sunday-saturday)
function nextDay(dayOfWeek, weekOffset) {
    const now = new Date();
    now.setDate(now.getDate() + (dayOfWeek + (7 - now.getDay())) % 7 + weekOffset * 7);
    return now;
}

// Returns the long name of a day by its week index.
function getDayByNumber(dayNumber) {
    if (dayNumber === undefined) {
        return ""; // Might indicate a network error.
    }

    switch (dayNumber) {
        case 1: return "Montag";
        case 2: return "Dienstag";
        case 3: return "Mittwoch";
        case 4: return "Donnerstag";
        case 5: return "Freitag";
        case 6: return "Samstag";

        default: return "Sonntag";
    }
}

// Retrieves the last order from the users' browser storage.
function setLastOrderData(data) {
    document.querySelector("#customer-name").value = data.customer.name;
    document.querySelector("#customer-phone").value = data.customer.phone;
    document.querySelector("#customer-mail").value = data.customer.mail;
}

// Forces two digits, for example for day of month.
function padZero(numericValue) {
    if (numericValue < 10)
        return '0' + numericValue.toString();

    return numericValue.toString();
}

// Take a Date value and turn it into a "2021-05-29T13:36:23" string
function createDateString(date) {
    return date.getFullYear() + '-' +
        padZero(date.getMonth() + 1) + '-' +
        padZero(date.getDate()) + 'T' +
        padZero(date.getHours()) + ':' +
        padZero(date.getMinutes()) + ':' +
        padZero(date.getSeconds());
}

function compareDays(date1, date2) {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();
}

// Only allow days of the weeks in the future at which orders
// from this baker are accepted.
function getDisabledDates(date, specialDates, baker) {
    const availableDates = getAvailableDates(specialDates, baker);

    for (let i = 0; i < availableDates.length; i++) {
        if (compareDays(availableDates[i].takeawayDate, date)) {
            const now = new Date();
            // Check if the dueDate for this day is already gone by.
            return now > availableDates[i].dueDate;
        }
    }
    return true;
}

function parseDateFromString(formattedString) {
    const year = parseInt(formattedString.substring(0, 4));
    const month = parseInt(formattedString.substring(5, 7)) - 1;
    const day = parseInt(formattedString.substring(8, 10));
    return new Date(year, month, day);
}