import React from 'react';
import { getDevice } from 'framework7/lite-bundle';
import {
  f7,
  f7ready,
  App,
  View,
} from 'framework7-react';
import cordovaApp from '../js/cordova-app';

import HomePage from './pages/bakerySelection';
import BakerOrder from './pages/bakerOrder';

const LodlOrdersApp = () => {
  const device = getDevice();
  // Framework7 Parameters
  const f7params = {
    name: 'LODL', // App name
    theme: 'auto', // Automatic theme detection

    id: 'de.lodl.bestellungen', // App bundle ID
    serviceWorker: {
      path: 'service-worker.js',
    },
    iosTranslucentBars: false,
    // Navbar config
    navbar: {
      hideOnPageScroll: true,
      iosCenterTitle: true,
      mdCenterTitle: true,
      auroraCenterTitle: true
    },

    // Input settings
    input: {
      scrollIntoViewOnFocus: device.cordova && !device.electron,
      scrollIntoViewCentered: device.cordova && !device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    routes: [
      {
        path: '/bestellungen/auswahl/',
        transition: 'f7-parallax',
        component: HomePage,
      },
      {
        path: '/bestellungen/:name/',
        transition: 'f7-parallax',
        component: BakerOrder,
      },
    ],
  };

  f7ready(() => {
    // Init cordova APIs (see cordova-app.js)
    if (f7.device.cordova) {
      cordovaApp.init(f7);
    }

    // Call F7 APIs here
  });

  return (
    <App {...f7params} >
      <View main url="/bestellungen/auswahl/"></View>
    </App>
  );
}
export default LodlOrdersApp;
