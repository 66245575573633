import React, { useState, useEffect } from 'react';
import {
    Link,
    List,
    ListItem,
    ListButton,
    Page,
    Popover,
    Navbar,
    Block,
} from 'framework7-react';
import axios from 'axios';
import 'framework7-icons';

import CircularProgressBar from './components/progressRing';

import addressbar from 'addressbar';

export default function (props) {
    // Network state
    const [backendAvailable, setBackendAvailable] = useState(false);

    // Menu data
    const [data, setData] = useState([]);

    const onBackButtonEvent = () => {
        if (location.href.includes("auswahl")) {
            props.f7router.back();
        }
        else if (location.href.includes("forster")) {
            props.f7router.navigate("/bestellungen/forster/", {});
        }
        else if (location.href.includes("duernberg")) {
            props.f7router.navigate("/bestellungen/duernberg/", {});
        }
        else if (location.href.includes("grill")) {
            props.f7router.navigate("/bestellungen/grill/", {});
        }
    }

    // In case the current URL refers to a nested page, open it.
    const checkCorrectUrl = () => {
        if (location.href.includes("forster")) {
            props.f7router.navigate("/bestellungen/forster/", { animate: false });
        }
        else if (location.href.includes("duernberg")) {
            props.f7router.navigate("/bestellungen/duernberg/", { animate: false });
        }
        else if (location.href.includes("grill")) {
            props.f7router.navigate("/bestellungen/grill/", { animate: false });
        }
    }

    useEffect(() => {
        getBakers();
        document.documentElement.style.setProperty('--f7-navbar-bg-color', '#f7f7f8');
        document.querySelector('meta[name="theme-color"]').setAttribute("content", '#f7f7f8');

        window.addEventListener('popstate', onBackButtonEvent);
        checkCorrectUrl();

        addressbar.value = `${addressbar.origin}/bestellungen/auswahl/`;
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    const getBakers = () => {
        axios.get(`${addressbar.origin}/bestellungen/auswahl/bakers`) // Will only work when served using "rocket".
            .then(result => {
                if (result.status == 200) {
                    setData({
                        menu: false,
                        bakers: result.data
                    });

                    setBackendAvailable(true);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Page name="home">
            <Navbar
                title="Bäckerei"
                style={{ textTransform: "uppercase" }}
                sliding={false}
                className="noprint">

                <Link popoverOpen=".popover-menu"
                    slot="right"
                    iconF7="ellipsis_vertical"></Link>
            </Navbar>

            <Block>
                {backendAvailable ?
                    <BakerList bakers={data.bakers}></BakerList>
                    :
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "inline-block" }}>
                            <CircularProgressBar></CircularProgressBar>
                        </div>
                    </div>
                }

                {/* Footer with company details */}
                <p style={{ textAlign: "center" }}>LODL – DORFLADEN&nbsp;LORENZREUTH&nbsp;UG . THIERSHEIMER STRASSE&nbsp;1 . 95615&nbsp;MARKTREDWITZ</p>
                <p style={{ textAlign: "center", lineHeight: "0.5em" }}><a href="https://dorfladen-lorenzreuth.de/IMPRESSUM/" onClick={() => openInNewTab("https://dorfladen-lorenzreuth.de/IMPRESSUM/")}>IMPRESSUM</a></p>
            </Block>

            <Popover className="popover-menu"
                onPopoverClose={() => {
                    document.querySelector('meta[name="theme-color"]').setAttribute("content", '#f7f7f8');
                }}
                onPopoverOpen={() => {
                    document.querySelector('meta[name="theme-color"]').setAttribute("content", '#949494');
                }}>
                <List>
                    <ListButton onClick={() => {
                        localStorage.clear();
                    }} popoverClose title="Daten zurücksetzen" />
                </List>
            </Popover>
        </Page>
    );
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const BakerList = (props) => {
    let bakers = [];

    props?.bakers?.forEach((baker) => {
        bakers.push(
            <ListItem link={"/bestellungen" + baker.orderLink} key={"/bestellungen" + baker.orderLink} header={baker.location} title={baker.name} after="Bestellen"></ListItem>
        );
    });

    return (
        <List>
            {bakers}
        </List>
    )
}