import React, { } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
100%{
    transform: rotate(360deg);
}
`;

const dash = keyframes`
0%{
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
}
50%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
}
100%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
}
`;

const SVG = styled.svg`
animation: ${rotate} 2s linear infinite;
position: relative;
height: 100px;
width: 100px;
`;

const CIRCLE = styled.circle`
stroke-dasharray: 1,200;
stroke-dashoffset: 0;
animation: ${dash} 1.5s ease-in-out infinite;
stroke-linecap: round;
stroke: #33a9ff;
`;

export default function () {
    return (
        <SVG>
            <CIRCLE
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="3"
                strokeMiterlimit="10"
            />
        </SVG>
    );
}
